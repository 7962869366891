import React from 'react'
import { Link, graphql } from 'gatsby'
import Remark from 'remark'
import html from 'remark-html'
import Content, { HTMLContent } from '../components/Content'
import Layout from '../components/Layout'
import styles from './style/story.module.scss'
import Masonry from 'react-masonry-component'

const CategoryStoryTemplate = ({ context, stories, contentComponent }) => {
  const StoryContent = contentComponent || Content
  const convertMarkdownToHtml = markdownString =>
    Remark()
      .use(html)
      .processSync(markdownString?.replace(/\\/g, '  '), (err, file) =>
        err ? { contents: '' } : file
      ).contents

  const masonryOptions = {
    transitionDuration: 0
  }

  const imagesLoadedOptions = { background: '.my-bg-image-el' }

  return (
    <Layout theme="Rattish">
      <article className="page">
        <div className="content">
          <main>
            <div className={styles.Content}>
              <h1 className={styles.Headline}>{context.category.name}</h1>

              <img
                className={styles.BackgroundCategory}
                src={context.category.featuredimage}
                alt=""
              />

              <StoryContent
                className="main-content"
                content={convertMarkdownToHtml(context.category.body)}
              />
            </div>

            <div className={styles.StoryBlockList}>
              <h3>Berättelser från andra</h3>
              <div className={styles.BlockList}>
                <Masonry
                  className={styles.Masonry}
                  elementType={'ul'}
                  options={masonryOptions}
                  imagesLoadedOptions={imagesLoadedOptions}>
                  {stories.edges.map(({ node }) => {
                    return (
                      <li key={node.frontmatter.uid}>
                        {node.frontmatter.storytype === 'story' && (
                          <div className={styles.BlockStory}>
                            <p>{node.frontmatter.description}</p>
                            <Link
                              to={`/rattish/${node.frontmatter.categories}/${node.frontmatter.uid}`}>
                              Läs hela berättelsen
                            </Link>
                          </div>
                        )}
                        {node.frontmatter.storytype === 'quoteBlack' && (
                          <div className={styles.BlockQuoteBlack}>
                            <p>{node.frontmatter.description}</p>
                          </div>
                        )}
                        {node.frontmatter.storytype === 'quoteYellow' && (
                          <div className={styles.BlockQuote}>
                            <p>{node.frontmatter.description}</p>
                          </div>
                        )}
                      </li>
                    )
                  })}
                </Masonry>
              </div>
            </div>
          </main>
        </div>
      </article>
    </Layout>
  )
}

export default ({ data, pageContext }) => {
  const { markdownRemark: post, allMarkdownRemark: edges } = data

  return (
    <CategoryStoryTemplate
      contentComponent={HTMLContent}
      context={pageContext}
      stories={edges}
    />
  )
}

export const CategoryStoryQuery = graphql`
  query CategoryStoryQuery($cat: String!) {
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { categories: { in: [$cat] } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            categories
            title
            description
            storytype
            uid
          }
        }
      }
    }
  }
`
